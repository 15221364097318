import './404.css';

import { Button } from 'react-bootstrap';
import Header from '../components/header';
import React from 'react';
import { Link } from 'gatsby';
import error from 'images/404.svg';
import errorimg from 'images/errorimg.svg';

const notFound = () => {
    return (
        <div>
            <Header />
            <div className="errordiv col-sm-12">
                <img style={{ position: 'absolute', marginTop: '80px' }} src={error} alt="something went wrong!" />
                <img src={errorimg} alt="Something went wrong!" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'cneter', marginTop: '300px' }}>
                <Link to='/'>
                    <Button className="button" >Go back to Home</Button>
                </Link>
            </div>
        </div>
    )
}

export default notFound;
